@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");

@import "sass/colors";
@import "sass/breakpoints";
@import "sass/reset";
@import "sass/normalize";
@import "sass/scene";
@import "sass/menu";
@import "sass/saved";
@import "sass/links";
@import "sass/modal";
@import "sass/mobile-header";
@import "sass/custom-scroll";
@import "sass/summary";

.css-1izlei8 {
  position: fixed !important;
  z-index: 2147483640 !important;
  svg {
    circle {
      stroke: $borderColor !important;
    }
  }
}

.landscape-check {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100vw;
  height: 100vh;
  z-index: 10000000;
  display: none;
  justify-content: center;
  align-items: center;
  .landscape-check-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-bottom: 20px;
    }
  }
}

@media screen and (orientation: portrait) and (min-width: 1024px) and (min-height: 1366px) {
  .landscape-check {
    display: flex;
  }
}
@media screen and (orientation: landscape) and (max-width: 1024px) and (min-height: 768px) {
  .landscape-check {
    display: flex;
  }
}
@media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 768px) {
  .landscape-check {
    display: flex;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  letter-spacing: 0.06em;
}

#root {
  background: #fafafa;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $tablet_width) {
    display: block;
  }
}

.app-holder {
  width: 1440px;
  padding: 60px 30px;
  height: 810px;
  position: relative;
  &:before,
  &:after {
    // content: "";
    position: absolute;
    top: -0px;
    right: -30px;
    width: 300px;
    height: 360px;
    background: url("./images/app-corner.png") 0 0 transparent no-repeat;

    @media screen and (min-width: 1140px) {
      content: "";
    }
  }

  &:before {
    top: initial;
    right: initial;
    bottom: -10px;
    left: -30px;
  }
  @media screen and (max-width: 1440px) {
    max-width: 100%;
    height: 720px;
  }

  @media screen and (max-width: 1400px) {
    max-width: 90%;
    margin: 0 auto;
    height: 600px;
    padding: 0;
  }
  @media screen and (max-width: 1320px) {
    max-width: 85%;
  }
  @media screen and (max-width: 1280px) {
    max-width: 78%;
    height: 570px;
    padding: 0;
  }

  @media screen and (max-width: 1140px) {
    max-width: 100%;
    padding: 0;
    width: 100vw;
    height: 100vh;
  }
}

#root {
  > div:nth-child(2) {
    z-index: 2147483647;
  }
}

.uppercase {
  text-transform: uppercase;
}

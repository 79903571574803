.room-selector {
  margin-left: 30px;
  display: flex;
  position: absolute;
  text-transform: uppercase;
  top: -32px;
  z-index: 2;

  @media screen and (max-width: $tablet_width) {
    display: none;
  }
}

.plan-selector {
  margin-left: 30px;
  display: flex;
  position: absolute;
  bottom: -32px;
  display: flex;
  text-transform: uppercase;
  align-items: flex-end;
  z-index: 3;
  .planner-box {
    &.active,
    &:hover {
      border-bottom-color: transparent;
      border-top-color: $borderColor;
    }
  }
  > div {
    position: relative;
    cursor: pointer;
  }

  @media screen and (max-width: $tablet_width) {
    display: none;
  }
}

.planner-box {
  width: 115px;
  height: 64px;
  background: white;
  text-transform: uppercase;
  font-size: 10px;
  color: #fff;
  padding: 12px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;
  // border-bottom: 2px solid transparent;
  // border-top: 2px solid transparent;

  .plan-delete-btn {
    display: block;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-image: url("../images/sub-menu-close-btn.png");
    background-size: cover;
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 3;
    opacity: 0.8;
    display: none;
    &:hover {
      opacity: 1;
    }
  }

  .item-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-size: cover;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
  }
  &:hover {
    border-bottom-color: $borderColor;
  }
  &.active {
    height: 72px;
    border-bottom-color: $borderColor;
    &:after {
      box-shadow: inset 0px 2px 0px 0px $borderColor;
    }
    .plan-delete-btn {
      display: flex;
    }
  }
  p {
    margin-top: auto;
    position: relative;
    z-index: 3;
  }
}

.saved-holder {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    display: block;
    overflow-y: auto;
    height: 70vh;
    padding: 0;
  }
  > div {
    width: 48%;
    margin: calc(1% / 3);
    height: 150px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &.active {
      position: relative;
      .plan-delete-btn {
        display: flex;
      }
      &:after {
        content: "";
        position: absolute;
        z-index: 10;
        bottom: 0;
        left: 0;
        top: 0;
        right: 0;
        box-shadow: inset 0px -4px 0px 0px $borderColor;
      }
    }

    @media screen and (max-width: 768px) {
      width: 90%;
      margin: 0 0 1% 0;
      &.active {
        width: 100%;
        &:after {
          box-shadow: inset -4px 0 0 0 $borderColor;
        }
      }
    }
  }

  .plan-delete-btn {
    border: 7px solid $grey;
    background: #fff;
    text-indent: -9999px;
    width: 44px;
    height: 44px;
    padding: 0;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    display: none;
    text-align: center;
    position: absolute;
    cursor: pointer;
    right: 12px;
    top: 8px;
    z-index: 15;
    opacity: 0.9;
    &:after {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-image: url("../images/delete-icon.png");
      background-position: center;
      background-size: 50%;
      background-repeat: no-repeat;
      top: 0;
      left: -1px;
    }
  }
}
.saved-item {
  text-transform: uppercase;
  font-size: 10px;
  color: #fff;
  padding: 12px;
  font-weight: 700;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;

  .item-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-size: cover;
  }

  //   border-left: 4px solid transparent;
  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  &:after {
    background: transparent;
  }
  &.active {
    border-color: $borderColor;
  }
  p {
    margin-top: 20px;
    position: relative;
    z-index: 2;
    font-size: 18px;
  }
}

.scene-holder {
  box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;

  // overflow: hidden;
  @media screen and (max-width: $tablet_width) {
    flex-direction: column;
  }
}

.scene {
  width: 85%;
  position: relative;
  background: #000;
  // z-index: 20;
  @media screen and (min-width: 1140px) and (max-height:799px){
    // max-height: 450px;
  }

  @media screen and (max-width: $tablet_width) {
    width: 100%;
    height: 100%;
    // max-height: 450px;
  }

  @media screen and (max-width: 812px) {
    max-height: 460px;
  }
  @media screen and (max-width: 680px) {
    max-height: 400px;
  }
  @media screen and (max-width: 640px) {
    max-height: 320px;
  }
  @media screen and (max-width: 480px) {
    max-height: 225px;
  }
}
.preview-holder {
  display: flex;
  flex-direction: column;
  z-index: 1;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 1;
 
  &.inner {
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    &.active {
      opacity: 1;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
}
.preview {
  position: relative;
  &:after {
    content: "";
    display: block;
    /* intrinsic aspect ratio */
    padding-bottom: 56.25%;
    height: 0;
  }
}

.preview-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: red;
}

@import "plan-selector";

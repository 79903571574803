.dimension-holder {
  display: flex;
  height: 100%;
  width: 15%;
  @media screen and (max-width: $tablet_width) {
    width: 100%;
    flex: 1;
  }
}
.side-bar {
  background: #fff;
  width: 100%;
  position: relative;
  z-index: 10;
}

.menu-holder {
  background: #fff;
  padding: 60px 0 40px;
  min-width: 150px;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  flex-direction: column;
  height: 100%;
  position: relative;
  box-shadow: -10px 0px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 2;
  @media screen and (max-width: $tablet_width) {
    padding: 30px 0;
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.user-holder {
  margin-bottom: 35px;
  padding: 0 25px;
  letter-spacing: -0.025em;
  text-transform: capitalize;
  @media screen and (max-width: $tablet_width) {
    display: none;
  }
}
.username {
  font-size: 20px;
  font-weight: 700;
}

.menu {
  font-size: 12px;
  font-weight: 600;
  border-top: 1px solid $light_grey;
  border-bottom: 1px solid $light_grey;
  @media screen and (max-width: $tablet_width) {
    border: none;
  }
  cursor: pointer;
}

.menu-item {
  border-bottom: 1px solid $light_grey;
  position: relative;
  background: #fff;
  cursor: pointer;
  &.active {
    a {
      border-color: $borderColor;
    }
    &:before {
      left: -12px;
      @media screen and (max-width: $tablet_width) {
        left: -10px;
      }
      @media screen and (max-width: 812px) {
        content: initial;
      }
    }
  }
  &:last-child {
    border: none;
  }

  &:before {
    content: "";
    position: absolute;
    width: 12px;
    height: 100%;
    background: url("../images/menu-active.png") 0 0 transparent no-repeat;
    background-size: contain;
    transition: left 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
    left: 0px;
  }

  a {
    color: $black;
    background: #fff;
    display: block;
    padding: 16px 25px;
    text-decoration: none;
    border-right: 2px solid transparent;
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    position: relative;
    z-index: 2;
    &:hover {
      border-color: $borderColor;
    }

    @media screen and (max-width: $tablet_width) {
      padding: 12px 30px;
    }

    @media screen and (max-width: 768px) {
      padding: 18px 30px;
      // border: none;
      // border-left: 2px solid transparent;
    }
  }
}

.action-holder {
  text-align: center;
  padding: 0 25px;
  position: absolute;
  bottom: 40px;
  z-index: 2;
  width: 100%;
  @media screen and (max-width: $tablet_width) {
    bottom: initial;
    top: -25px;
    right: 0px;
    z-index: 20;
    display: flex;
    align-items: flex-start;
    padding: 0 10px 0 0;
    width: auto;
    > a {
      margin: 0 10px;
    }
  }
}

.sub-menu-holder {
  position: absolute;
  overflow: hidden;
  width: 320px;
  height: 100%;
  left: -320px;
  display: flex;
  visibility: hidden;
  &.open {
    visibility: visible;
  }

  @media screen and (max-width: $tablet_width) {
    left: 50%;
    // z-index: 10;
    width: 50%;
  }
  @media screen and (max-width: 812px) {
    left: 50%;
    // width: 100%;
    z-index: 2;
  }
  @media screen and (max-width: 480px) {
    z-index: 3;
    left: 15%;
    width: 85%;
  }
}

.mobile-closer {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0009;
  top: 0;
  z-index: 100;
  @media screen and (max-width: 480px) {
    &.open {
      display: block;
    }
  }
}

.sub-menu {
  background: #fafafa;
  width: 100%;
  height: 100%;
  left: 100%;
  position: relative;
  transition: left 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  padding: 100px 50px 50px 50px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  @media screen and (max-width: $tablet_width) {
    padding: 30px 40px;
  }
  @media screen and (max-width: 812px) {
    padding: 40px 30px;
  }
  @media screen and (max-width: 640px) {
    padding: 40px 20px;
  }

  span {
    display: block;
    color: $grey;
  }
  &.open {
    left: 0;
  }
  &.closing {
    left: 100%;
  }

  .close-btn {
    background-image: url("../images/sub-menu-close-btn.png");
    width: 44px;
    height: 44px;
    background-size: 100%;
    position: static;
    cursor: pointer;
  }
}

.sub-items-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
}

.open {
  .sub-items {
    display: flex;
  }
}
.sub-items {
  margin-top: 40px;

  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 480px;
  display: none;
  // position: relative;
  > div {
    display: flex;
    align-items: center;
    @media screen and (max-width: $tablet_width) {
      width: 100%;
    }
  }
  .item-label {
    font-size: 11px;
    display: none;
    margin-left: 10px;
    @media screen and (max-width: $tablet_width) {
      display: block;
    }
  }
  .item-block {
    cursor: pointer;
    border: 2px solid #fafafa;
    width: 54px;
    height: 54px;
    background-size: cover;
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    // border: 1px solid $grey;
    overflow: hidden;
    box-shadow: inset 0px 0px 0px 2px $grey;
    &:hover,
    &.active {
      box-shadow: inset 0px 0px 0px 3px $borderColor;
    }
  }

  @media screen and (max-width: $tablet_min_width) {
    margin-top: 20px;
    max-height: 380px;
  }
  @media screen and (max-width: $tablet_width) and (orientation: landscape) {
    max-height: 140px;
  }

  @media screen and (max-width: 768px) {
    max-height: 45vh;
  }
  @media screen and (max-width: 420px) {
    max-height: 40vh;
  }
}

.item-preview {
  background: #fff;
  position: fixed;
  pointer-events: none;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.5ms ease-in-out;
  font-size: 12px;
  font-weight: 600;
  visibility: hidden;
  padding: 5px;
  box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.3);
  max-width: 162px;
  .preview-name {
    line-height: 1.4;
    margin: 5px 0;
  }
  .preview-img {
    width: 152px;
    min-height: 152px;
    background-size: cover;
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }

  @media screen and (max-width: $tablet_width) {
    display: none;
  }
}

@media screen and (max-width: $tablet_width) {
  .btn-mobile-add,
  .btn-mobile-revert,
  .btn-mobile-delete {
    border: 7px solid $grey;
    background: #fff;
    text-indent: -9999px;
    width: 44px;
    height: 44px;
    padding: 0;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    position: relative;
    cursor: pointer;
    &:after {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-image: url("../images/delete-icon.png");
      background-position: center;
      background-size: 50%;
      background-repeat: no-repeat;
      top: 0;
      left: -1px;
    }
  }

  .btn-mobile-revert {
    &:after {
      background-image: url("../images/mobile-revert-icon.png");
    }
  }

  .btn-mobile-add {
    &:after {
      background-image: url("../images/btn-plus.svg");
    }
  }

  .menu-scroll-holder {
    max-height: 190px;
    overflow-y: auto;
    @media screen and (orientation: portrait) {
      max-height: 320px;
    }
  }
}

.mobile-selection-alert {
  position: absolute;
  z-index: 1;
  z-index: 25;
  font-size: 13px;
  color: #808080;
  left: 50%;
  padding: 40px;
  display: none;

  @media screen and (max-width: $tablet_width) {
    display: block;
    &.open {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    display: none;
  }
}

.summary {
}
.summary-items-holder {
  height: 210px;
  padding-left: 30px;
  @media screen and (max-width: 640px) {
    padding-left: 10px;
    // height: 120px;
  }
}
.summary-items {
  display: flex;
  flex-wrap: wrap;
}
.summary-item {
  width: calc(33.33% - 8px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
  border: 1px solid #f6f6f6;
  padding: 12px;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  color: #000000;
  text-transform: uppercase;

  @media screen and (max-width: 768px) and (orientation: portrait) {
    width: calc(50% - 8px);
  }

  span {
    font-size: 9px;
    text-transform: none;
    color: #b5b5b5;
  }
  img {
    width: 100%;
    max-width: 60px;
    height: auto;
    margin: 16px 0;
  }
}

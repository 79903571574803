.mobile-header {
  display: none;
}
@media screen and (max-width: $tablet_width) {
  .mobile-header {
    background: #fff;
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
  }

  .btn-icon {
    
    height: 44px;
    width: 44px;
    color: $black;
    display: inline-flex;
    align-items: center;
    background-position: center left;
    background-repeat: no-repeat;
    justify-content: center;
    cursor: pointer;

    &:last-child{
      margin: 0;
    }

    &:hover {
      color: $black;
      text-decoration: none;
    }

    &.plan-switcher {
      width: auto;
      height: 44px;
      line-height: 16px;
      font-size: 14px;
      margin-right: 10px;
      text-decoration: none;
      text-indent: 44px;
      background-position: center left;
      background-size: 36px;
      background-image: url("../images/plan-switcher.svg");
      &:last-child {
        margin: 0;
      }
    }
  }
}

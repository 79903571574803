.btn {
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  padding: 15px 20px;
  border-radius: 50px;
  background: $buttonColor;
  display: inline-block;
  margin: 10px 0;
  width: 100%;
  text-align: left;
  position: relative;
  width: 150px;
  cursor: pointer;

  &.quit-delete-btn {
    background: $grey;
    color: #fff;
    text-align: center;
    margin-right: 10px;
    &:after {
      content: initial;
    }
  }

  @media screen and (max-width: 1366px) {
    width: 120px;
  }
  @media screen and (max-width: 768px) {
    width: 140px;
  }
  &:focus {
    box-shadow: none;
  }
  &:after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: url("../images/btn-arrow.svg") center #fff no-repeat;
    right: 10px;
    top: 5px;
    // top: calc(-50%);
    // transform: translateY(50%);
  }
}

.area-pointer {
  cursor: pointer;
  text-indent: -9999px;
  z-index: 10000;
  width: 44px;
  height: 44px;
  padding: 0;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  position: absolute;
  z-index: 1000;
  top: 35%;
  left: 80%;
  border: 8px solid $borderColor;
  background: #fff;
  &:after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: url("../images/btn-plus.svg") center #fff no-repeat;
  }
}

.small-btn {
  text-indent: -9999px;
  width: 44px;
  padding: 0;
  height: 44px;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  &:after {
    position: initial;
    background-image: url("../images/btn-plus.svg");
  }
}

.grey-links {
  font-size: 11px;
  font-weight: 600;
  margin-top: 10px;
  color: $grey;
  display: inline-flex;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: $buttonColor;
  }
}

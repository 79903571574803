#modal {
}

.modal-holder {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.content-holder {
  width: 80%;
  max-width: 800px;
  height: 300px;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
  @media screen and (max-width: 640px) {
    flex-direction: column;
    height: auto !important;
  }

  .go-back-btn {
    color: $grey;
    font-size: 11px;
    top: -25px;
    display: inline-block;
    position: absolute;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
      color: $buttonColor;
    }
    @media screen and (max-width: 768px) {
      position: relative;
      top: 0;
      margin-bottom: 15px;
    }
  }

  &.saved-modal-holder {
    justify-content: flex-start;
  }

  &.delete-holder {
    height: auto;
    .btn {
      margin-top: 45px;
      width: 120px;
      &.quit-delete-btn {
        width: 130px;
        padding: 15px 12px;
      }
    }
  }

  &.disclaimer-holder,
  &.summary-holder {
    width: 70%;
    height: 375px;
    .summary {
      max-width: 700px;
    }
    @media screen and (max-width: 768px) {
      height: auto;
      width: 80%;
    }
    @media screen and (max-width: 640px) {
      max-width: 80%;
    }
  }

  &.register-holder {
    height: 365px;
    width: 100%;
    .content {
      display: flex;
      flex: 1;
      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    @media screen and (max-width: 768px) {
      height: auto;
      width: 80%;
    }
  }

  @media screen and (max-width: $tablet_width) and (orientation: landscape) {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .title {
    width: 33%;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: -0.025em;
    padding: 60px;
    // textpho -transform: uppercase;
    @media screen and (max-width: $tablet_width) {
      height: auto;
      width: 100%;
      padding: 20px;
    }
    @media screen and (max-width: 768px) {
      padding-left: 0;
    }
  }
  .content {
    width: 67%;
    position: relative;
    // display: flex;
    // flex: 1;

    > div {
      position: relative;
    }

    @media screen and (max-width: $tablet_width) {
      width: 100%;
    }
    @media screen and (max-width: 812px) {
      position: static;
    }
  }

  @media screen and (max-width: $tablet_width) {
    height: 80%;
  }
}

.name-surname-holder {
  display: flex;

  .row:nth-child(1) {
    width: 50%;
    margin-right: 10px;
  }
  .row:nth-child(2) {
    width: 50%;
    margin-left: 10px;
  }

  @media screen and (max-width: 680px) {
    display: block;
    .row {
      margin: 0 !important;
      margin-top: 20px !important;
    }
  }

  @media screen and (max-width: 640px) {
    .row {
      width: 100% !important;
      margin: 0 !important;
      margin-top: 5px !important;
    }
  }
}

.box-content-holder {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: $tablet_min_width) {
    flex-direction: column-reverse;
  }
  &:after {
    position: absolute;
    content: "";
    width: 290px;
    height: 210px;
    z-index: 1;
    bottom: -40px;
    left: -40px;
    background: url("../images/modal-box-bg.png") 0 0 transparent no-repeat;
  }

  .btn {
    width: 140px;
  }

  &.register {
    .box-content {
      margin-top: 30px;
      margin-bottom: auto;
    }
    .btn-holder {
      justify-content: flex-end;
      margin-right: -15px;

      @media screen and (max-width: 640px) {
        display: flex;
      }
    }
    .box {
      // padding-right: 25px;
      background: #fafafa;
      &:nth-child(2n) {
        background: #fff;
      }
      .btn {
        min-width: 140px;
        margin-top: 25px;
      }
      .small-btn {
        min-width: initial;
        width: 44px;
      }
    }
  }
}
.box {
  flex: 1;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 45px 35px 0 35px;
  background: #fff;

  @media screen and (max-width: 640px) {
    padding: 25px 20px 0 20px;
  }
  &:nth-child(2n) {
    background: #fafafa;
    box-shadow: inset 6px 0 12px -9px rgba(0, 0, 0, 0.3);
    @media screen and (max-width: $tablet_min_width) {
      box-shadow: inset 0px -6px 12px -9px rgba(0, 0, 0, 0.3);
    }
  }

  &.confirmation {
    justify-content: center;
    text-align: center;
    padding: 0;

    @media screen and (max-width: 768px) {
      padding: 30px;
    }

    h2 {
      margin: 0;
    }
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.025em;
    margin-bottom: 25px;
  }
  p {
    font-size: 11px;
    color: $grey;
    letter-spacing: 0.06em;
  }
  .btn {
    margin-top: 85px;
    margin-bottom: -20px;

    @media screen and (max-width: 640px) {
      &:nth-child(2n) {
        margin-top: 20px;
      }
    }

    &.btn-discalimer {
      margin-top: 45px;
    }
  }

  .btn-holder {
    align-self: flex-end;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    width: 100%;

    &.delete-btns {
      justify-content: flex-end;
    }

    &.align-right {
      justify-content: flex-end;
    }

    @media screen and (max-width: 640px) {
      display: block;
    }

    .grey-links {
      margin-top: 70px;
      font-size: 11px;
      cursor: pointer;

      @media screen and (max-width: 640px) {
        margin-top: 45px;
      }
      @media screen and (max-width: 480px) {
        margin-top: 25px;
      }
    }
  }
}

input {
  border: 1px solid $grey;
  border-radius: 100px;
  // height: 50px;
  width: 100%;
  padding: 18px 25px;
  font-weight: 500;
  font-size: 12px;
  margin-top: 30px;
  box-shadow: none;
  text-transform: uppercase;
  color: $black;
  &:focus {
    outline: 0;
  }
  @media screen and (max-width: $tablet_min_width) {
    margin-top: 20px !important;
  }
  @media screen and (max-width: 360px) {
    margin-top: 5px !important;
    padding: 12px 24px;
  }
}

.legal-terms {
  font-size: 11px;
  line-height: 15px;
  color: $grey;
  height: 210px;
  // overflow-y: auto;
  overflow: hidden;
  p {
    margin-bottom: 15px;
  }
}

.close-btn {
  background-image: url("../images/sub-menu-close-btn.png");
  width: 44px;
  height: 44px;
  background-size: 100%;
  position: absolute;
  right: -22px;
  top: -22px;
  z-index: 20;
  text-indent: -99999px;
  display: block;
  cursor: pointer;

  @media screen and (max-width: 812px) {
    top: 22px;
    right: 22px;
  }
}

.srv-validation-message {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 10px;
  color: #e61a1a;
}
